import { createContext, useState, useEffect } from "react";

export const Contextone = createContext(null);

export const CounterProvider = (props) => {

    const [isLogoutButton, setIsLogOutButton] = useState(false);
    const [name, setName] = useState(false);
    const [isPremium, setisPremium] = useState(false);
    const [isTid, setIsTid] = useState(1);
    const [isHomeId, setIsHomeId] = useState(1);
    const [isDomain, setDomain] = useState("");
    const [isLogo, setLogo] = useState("");
    const [isTitle, setTitle] = useState("");
    const [isSwitchEmail, setSwitchEmail] = useState(false);
    const [isPlayerStoreId,setPlayStoreId] = useState("");
    const [isAppStoreId, setAppStoreId] = useState("");
    const [isNavbarTitle, setNavbarTitle] = useState("");
    const [googleVerificationCode, setgoogleVerificationCode] = useState("");

    const [showNewsContent, setShowNewsContent] = useState(() => {
    const savedShowNewsContent = localStorage.getItem('showNewsContent');
        return savedShowNewsContent !== null ? JSON.parse(savedShowNewsContent) : false;
    });

    useEffect(() => {
        localStorage.setItem('showNewsContent', JSON.stringify(showNewsContent));
    }, [showNewsContent]);

    return (
        <Contextone.Provider value={{
            isSwitchEmail,
            setSwitchEmail,
            setShowNewsContent,
            showNewsContent,
            isDomain,
            setDomain,
            setLogo,
            isLogo,
            setNavbarTitle,
            isNavbarTitle,
            setTitle,
            isTitle,
            isLogoutButton,
            setIsLogOutButton,
            name,
            setName,
            isPremium,
            setisPremium,
            isTid,
            setIsTid,
            isHomeId,
            setIsHomeId,
            isPlayerStoreId,
            setPlayStoreId,
            isAppStoreId,
            setAppStoreId,
            setIsHomeId,
            isPlayerStoreId,
            setPlayStoreId,
            isAppStoreId,
            setAppStoreId,
            googleVerificationCode,
            setgoogleVerificationCode
        }}>
            {props.children}
        </Contextone.Provider>
    );
};
